<template>
  <div
    class="LevelSuccessView"
    :class="{ showingLevelGroupCompletedPopup, blockInteraction }">
    <GameScrollableWithStickyHeader
      ref="scrollContainer"
      class="scrollContainer"
      :sticky-height="headerStickyHeight"
      @scroll="onHeaderScroll">
      <div
        slot="header"
        class="header"
        :style="{ backgroundImage: `url(${theme.coverImage})` }">
        <div
          ref="title"
          class="title">
          <span>
            {{ $t('level') }}
            {{ level.groupIndex + 1 }}.{{ level.localIndex + 1 }}
          </span>
          {{ $t('completed') }}
        </div>
        <div
          ref="subtitle"
          class="subtitle"
          :style="{ color: theme.primaryColor }">
          <span v-if="stars === 3">{{ $t('perfectScore') }}</span>
          <span v-else>{{ $t('passedScore') }}</span>
        </div>

        <div
          ref="targetHeightHelper"
          class="starsContainer targetHeightHelper">
          <div
            ref="stars"
            class="stars">
            <svgicon
              v-for="star of 3"
              :key="star"
              class="Star"
              :class="{ isFull: star <= stars }"
              icon="star" />
          </div>
        </div>
      </div>

      <div
        slot="body"
        ref="body"
        class="body">
        <div
          v-if="showRanking"
          class="ranking">
          <div class="rankingAvatar" />
          <div class="rankingName">
            Nasos Kouroupis
          </div>
          <div class="rankingValuePrevious">
            1287
          </div>
          <svgicon
            class="rankingArrowIcon"
            icon="arrow-right" />
          <div class="rankingValue">
            1039
          </div>
        </div>

        <div
          ref="continueButton"
          class="stickyContainer"
          :style="{ top: `${headerStickyHeight2}px` }">
          <div
            v-if="nextLevel"
            class="continueButton --global-clickable"
            :style="{ backgroundColor: theme.primaryColor, color: theme.textColorOnPrimaryColor }"
            @click="playNextLevel()">
            {{ $t('continue') }}
            {{ nextLevel.groupIndex + 1 }}.{{ nextLevel.localIndex + 1 }}
          </div>
        </div>

        <StandardSection
          class="timeSection"
          :label="$t('timeHeader')">
          <div class="times">
            <div class="Time">
              <div>{{ $t('learningTime') }}</div>
              <div>
                <span class="Time__value">{{
                  (sessionReport.memorizeTimeUsed / 1000).toFixed(1)
                }}</span>
                {{ $t('seconds') }}
              </div>
            </div>

            <div class="Time">
              <div>{{ $t('memoryTime') }}</div>
              <div>
                <span class="Time__value">{{ (sessionReport.quizTimeUsed / 1000).toFixed(1) }}</span>
                {{ $t('seconds') }}
              </div>
            </div>
          </div>
        </StandardSection>

        <StandardSection
          class="quizDetails"
          :label="$t('yourAnswers')">
          <SessionReport :items="sessionReport.items" />
        </StandardSection>
      </div>
    </GameScrollableWithStickyHeader>

    <GameHeader
      ref="top"
      class="top"
      :countdown="-1"
      @request-exit="exit()" />

    <transition
      name="popup"
      :duration="1000"
      mode="out-in">
      <div
        v-if="showingLevelGroupCompletedPopup"
        class="popupContainer">
        <LevelGroupCompletedPopup :number="level.groupIndex + 1" />
        <div
          class="popupCloseButton --global-clickable"
          @click="showingLevelGroupCompletedPopup = false">
          <svgicon
            class="closeIcon"
            icon="close" />
        </div>
      </div>
    </transition>
  </div>
</template>

<translations>
  perfectScore: 'Perfect Score'
  perfectScore_no: 'Perfekt'
  passedScore: 'Well done'
  passedScore_no: 'Bra jobba'
  yourAnswers: 'Your answers'
  yourAnswers_no: 'Dine svar'
  level: 'Level'
  level_no: 'Nivå'
  completed: 'Completed'
  completed_no: 'Ferdig'
  continue: 'Continue with level'
  continue_no: 'Fortsett med nivå'
  backToSystem: 'Back to Name System'
  backToSystem_no: 'Tilbake til Navnesystemet'
  timeHeader: 'Your time'
  timeHeader_no: 'Tiden din'
  learningTime: 'Memorization Time'
  learningTime_no: 'Memoreringstid'
  memoryTime: 'Recall Time'
  memoryTime_no: 'Husketid'
  seconds: 'sec'
  seconds_no: 'sek'
</translations>

<script>
import { TimelineLite } from 'gsap';
import upperFirst from 'lodash-es/upperFirst';

import GameScrollableWithStickyHeader from '@/components/GameScrollableStickyHeader';

import LevelGroupCompletedPopup from '../components/LevelGroupCompletedPopup';
import StandardSection from '../components/StandardSection';
import SessionReport from '../components/SessionReport';
import gameHelper from '@/modules/games-shared/game-helper';
import GameHeader from '@/modules/games-shared/components/GameHeader';

export default {
    name: 'LevelSuccessView',
    components: {
        GameScrollableWithStickyHeader,
        LevelGroupCompletedPopup,
        StandardSection,
        SessionReport,
        GameHeader
    },
    props: {
        gameId: {
            type: String,
            required: true
        },
        levelIndex: {
            type: Number,
            required: true
        },
        sessionId: {
            type: String,
            required: true
        }
    },
    inject: ['theme'],
    data() {
        return {
            showingLevelGroupCompletedPopup: false,
            blockInteraction: false,
            headerStickyHeight2: 0,
            showRanking: false
        };
    },
    computed: {
        level() {
            return this.$store.state[`moduleGame${upperFirst(this.gameId)}`].levels[this.levelIndex];
        },
        nextLevel() {
            return this.$store.state[`moduleGame${upperFirst(this.gameId)}`].levels[this.levelIndex + 1] || null;
        },
        stars() {
            return this.sessionReport.stars;
        },
        isLastLevelInGroup() {
            const levelGroup = this.$store.state[`moduleGame${upperFirst(this.gameId)}`].levelGroups[
                this.level.groupIndex
            ];
            return levelGroup.levelIndices[levelGroup.levelIndices.length - 1] === this.level.index;
        }
    },
    created() {
        this.sessionReport = gameHelper.sessions[this.sessionId].generateReport(this.$t.bind(this));
    },
    mounted() {
        this.headerStickyHeight2 = this.headerStickyHeight();
        let tl = new TimelineLite();
        tl.pause();
        tl.seek(0);

        tl.staggerFromTo(
            [this.$refs.title, this.$refs.subtitle],
            1,
            {
                opacity: 1
            },
            {
                opacity: 0
            },
            0
        );

        tl.fromTo(
            this.$refs.stars,
            1,
            {
                yPercent: 50,
                scale: 1
            },
            {
                yPercent: 0,
                scale: 0.7
            },
            0
        );

        tl.fromTo(
            this.$refs.body,
            1,
            {
                paddingTop: '2em'
            },
            {
                paddingTop: '0em'
            },
            0
        );
        this.tl = tl;

        this.$refs.scrollContainer.$el.addEventListener('scroll', this.onBodyScroll);
    },
    beforeDestroy() {
        this.tl.kill();
        this.$refs.scrollContainer.$el.removeEventListener('scroll', this.onBodyScroll);
        clearTimeout(this.completedPopupTimeoutId);
    },
    methods: {
        headerStickyHeight() {
            if (this.$refs.targetHeightHelper) {
                return this.$refs.targetHeightHelper.clientHeight;
            } else {
                return 0;
            }
        },
        onHeaderScroll(fraction) {
            this.tl.time(fraction);
            if (fraction === 1) {
                this.stickySectionScrollTopTrigger = this.$refs.continueButton.offsetTop - this.headerStickyHeight2;
            }
        },
        onBodyScroll(event) {
            if (event.target.scrollTop >= this.stickySectionScrollTopTrigger) {
                this.$refs.continueButton.classList.add('sticking');
            } else {
                this.$refs.continueButton.classList.remove('sticking');
            }
        },
        exit() {
            this.$router.push({
                name: 'MemogeniusGame-Levels'
            });
        },
        playNextLevel() {
            this.$router.push({
                name: 'MemogeniusGame-LevelSession',
                params: {
                    levelIndex: this.nextLevel.index
                }
            });
        },
        testPopup() {
            this.showingLevelGroupCompletedPopup = true;
        }
    }
};
</script>

<style scoped lang="scss">
.LevelSuccessView {
    display: flex;
    flex-direction: column;

    $transitionDuration: 0.5s;
    &.banner_to_none-leave-active .body,
    &.banner_to_none-leave-active .header > * {
        transition: opacity $transitionDuration;
    }
    &.banner_to_none-leave-to .body,
    &.banner_to_none-leave-to .header > * {
        opacity: 0;
    }

    &.showingLevelGroupCompletedPopup {
        .header,
        .body {
            opacity: 0;
        }
    }

    &.blockInteraction::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
}
.header {
    height: 12em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba(black, 0.5);

    background-size: cover;
    background-position: center bottom;
}
.targetHeightHelper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $memogeniusShared-headerHeight;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    justify-content: flex-end;
}
.title {
    font-size: 140%;
}
.subtitle {
    margin-top: 0.5em;
}
.body {
    flex: 1;
    padding: 2em;
    padding-top: 0;

    display: flex;
    flex-direction: column;

    & > * {
        flex-shrink: 0;
    }
}

.stars {
    display: flex;
    justify-content: center;
}

.Star {
    $size: 3em;
    width: $size;
    height: $size;

    fill: #464392; // Color for game-faces
    margin: 0 0.3em;

    &.isFull {
        fill: #f2c500;
    }
}

.stats {
    display: flex;
    justify-content: space-between;

    margin-top: 1em;
}
.StatsItem {
}
.StatsItemLabel {
    opacity: 0.5;
    margin-bottom: 0.2em;
}
.StatsItemValue {
    font-size: 120%;
    color: white;
}

.ranking {
    display: flex;
    align-items: center;
    margin-top: 2em;
}
.rankingAvatar {
    $size: 2em;
    width: $size;
    height: $size;
    background-color: white;
    border-radius: 50%;
    margin-right: 1em;
}
.rankingName {
    flex: 1;
}
.rankingArrowIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
    margin: 0 0.5em;
}

.stickyContainer {
    position: sticky;
    top: 12em;
    margin-top: 2em;

    &.sticking {
        .continueButton {
            margin-left: -2em;
            margin-right: -2em;
            border-radius: 0;
        }
    }
}
.continueButton {
    padding: 1em 2em;
    color: white;
    align-self: stretch;
    text-align: center;
    border-radius: 0.2em;

    $transitionDuration: 0.2s;
    transition: margin $transitionDuration, border-radius $transitionDuration;
}

.spacer {
    flex: 1;
}
.times {
    display: flex;
    margin-top: 1em;
}
.Time {
    flex: 1;
    background: rgba(black, 0.2);
    padding: 1em;
    border-radius: 0.5em;

    &:not(:first-child) {
        margin-left: 1em;
    }
}
.Time__value {
    font-size: 140%;
}

.quizDetails {
    margin-top: 2em;
}

.timeSection {
    margin-top: 3em;
}

.popupContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    $transitionDuration: 0.5s;
    &.popup-enter-active .LevelGroupCompletedPopup,
    &.popup-leave-active .LevelGroupCompletedPopup {
        transition: opacity $transitionDuration, transform $transitionDuration;
    }
    &.popup-enter .LevelGroupCompletedPopup {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0.5);
    }
    &.popup-leave-to .LevelGroupCompletedPopup {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0.5);
    }

    $transitionDuration: 0.5s;
    &.popup-enter-active .popupCloseButton,
    &.popup-leave-active .popupCloseButton {
        transition: opacity $transitionDuration, transform $transitionDuration;
    }
    &.popup-enter-active .popupCloseButton {
        transition-delay: 0.5s;
    }
    &.popup-enter .popupCloseButton {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0.5);
    }
    &.popup-leave-to .popupCloseButton {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0.5);
    }
}
.LevelGroupCompletedPopup {
    width: 20em;
    height: 25em;
}
.popupCloseButton {
    $size: 3.5em;
    position: absolute;
    left: calc(50% - #{$size / 2});
    bottom: 2em;
    width: $size;
    height: $size;
    border: 1px solid rgba(white, 0.7);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}
.closeIcon {
    $size: 0.8em;
    width: $size;
    height: $size;
    fill: white;
}
</style>
