<template>
  <div class="LevelGroupCompletedPopup">
    <div class="header">
      <div class="title">
        {{ $t('title', { level: number }) }}
      </div>
    </div>

    <div class="body">
      <div class="graphicsContainer">
        <div class="rotatingPattern" />
        <div class="ornaments" />
        <div class="number">
          {{ number }}
        </div>
      </div>

      <!-- <div class="text1">
        {{ $t('subtitle') }}
      </div> -->
      <!-- <div v-if="nextLevel" class="text2">
        {{ $t('nextLevel') }}
      </div> -->
    </div>
    <!-- <div class="footer">
      <div v-if="nextLevel" class="continueButton --global-clickable" :xstyle="{ backgroundColor: theme.primaryColor }" @click="playNextLevel()">
        {{ $t('continue', {level: nextLevel.groupIndex + 1, sublevel: nextLevel.groupIndex + 1}) }}
      </div>
      <div class="shareButton">Share Achievement</div>
    </div> -->
  </div>
</template>

<translations>
  title: 'Level {level} Completed!'
  title_no: 'Nivå {level} Fullført!'
  subtitle: 'What a great job!'
  subtitle_no: 'Bra jobba!'
  nextLevel: 'Are you ready to level up?'
  nextLevel_no: 'Klar for neste nivå?'
  continue: 'I''m ready for {level}.{sublevel}'
  continue_no: 'Jeg er klar for {level}.{sublevel}'
</translations>

<script>
export default {
    props: {
        number: {
            type: Number,
            required: true
        }
    },
    inject: ['module', 'transition', 'theme']
    // computed: {
    //   level () {
    //     return this.module.state.levels[this.module.state.s.levelInfo.levelIndex]
    //   },
    //   nextLevel () {
    //     return this.module.state.levels[this.module.state.s.levelInfo.levelIndex + 1] || null
    //   }
    // },
    // methods: {
    //   playNextLevel () {
    //     this.transition('NEXT_LEVEL', { levelIndex: this.nextLevel.index })
    //   }
    // }
};
</script>

<style scoped lang="scss">
.LevelGroupCompletedPopup {
    background-color: white;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    color: rgba(black, 0.8);
}
.header {
    padding: 2em;
}
.title {
    // color: black;
    font-size: 120%;
    text-align: center;
    font-weight: 600;
}

.body {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-bottom: 3em;
    padding-top: 0;
}

.graphicsContainer {
    $size: 16em;
    width: $size;
    height: $size;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}

.rotatingPattern {
    // $size: 5em;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // width: $size;
    // height: $size;
    background-image: url('./radial-stripes.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    opacity: 0.1;

    animation: rotate 30s infinite linear;

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}

.text1 {
    font-weight: 600;
    color: black;
    margin-top: 1em;
}
.text2 {
    line-height: 1.5em;
    color: rgba(black, 0.7);
    max-width: 20em;
    margin-top: 0.5em;
    text-align: center;
}

.ornaments {
    $size: 80%;
    width: $size;
    height: $size;
    background-image: url('./ornaments.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3;

    display: flex;
    justify-content: center;
    align-items: center;
}

.number {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 500%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.footer {
    padding: 1em;
}
.continueButton {
    padding: 1em 2em;
    color: black;
    border: 1px solid black;
    align-self: stretch;
    text-align: center;
    border-radius: 0.2em;
}
</style>
